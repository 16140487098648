<template>
    <setting-layout :title="$t('pages.user.setting.creator.verify_ondato.title')">
        <v-container>
            <v-flex text-center>
                <h1>{{ $t('pages.user.setting.creator.verify_ondato.verify_account') }}:</h1>
            </v-flex>
            <div v-if="!(!!user.verify_at)" class="text-center mb-4">
                {{ statusMessage(verificationUser) }}
            </div>
            <div v-if="!!user.verify_at" class="success-message">
                <v-icon>mdi-checkbox-marked-circle</v-icon>
                {{ successMessage }}
            </div>
            <div class="text-center" v-if="isShowVerificationButton">
                <v-btn link
                       black-text
                       color="white"
                       :loading="loading.verificationUser"
                       @click="toVerificationUserLink"
                >{{$t('pages.user.setting.creator.verify_ondato.action_link')}}
                </v-btn>
            </div>
            <div class="AnotherModels-wrapper" v-if="showAnotherModels">
                <div class="AnotherModels-wrapper" v-if="showAnotherModels">
                    <div v-for="(item, key) in anotherModels" :key="key" class="AnotherModels-item">
                        <div v-if="item.verify_at === null" class="d-flex justify-space-between">
                            <div>
                                {{ statusMessageAnotherModel(item) }}
                            </div>
                            <v-btn link
                                   black-text
                                   color="white"
                                   :loading="!!item.loading"
                                   v-if="isShowAnotherModelLink(item)"
                                   @click="toVerificationAnotherModelLink(item)"
                            >{{$t('pages.user.setting.creator.verify_ondato.action_link')}}
                            </v-btn>
                        </div>
                        <div v-else>
                            <div class="success-message">
                                <v-icon>mdi-checkbox-marked-circle</v-icon>
                                {{
                                    $tc('pages.user.setting.creator.verify_ondato.model_has_verified', item.id, {name: item.id})
                                }}
                                {{ moment(item.verify_at).format('L') }}
                            </div>
                        </div>
                    </div>
                    <div class="AnotherModels-buttons">
                        <gradient-button v-if="!hasUnverifiedModel" @click="handleAddAnotherModel" class="ma-2">
                            {{ $t('pages.user.setting.creator.verify_ondato.add_another_model') }}
                        </gradient-button>
                    </div>
                </div>
            </div>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout"
import {mapActions, mapState} from "vuex"

import moment from "moment";
import GradientButton from "@/components/app/button/GradientButton";

const STATUS_CREATED = 1;
const STATUS_PROCESSED = 2;
const STATUS_RESOLVED = 3;
const STATUS_REJECTED = 4;

export default {
    name: "VerifyOndato",
    components: {
        GradientButton,
        SettingLayout,
    },
    data() {
        return {
            loading: {
                verificationUser: false,
                verificationAnotherModel: false,
            },
            anotherModels: [],
            verificationUser: {status: null, date: null},
        }
    },
    computed: {
        ...mapState({
            fetchedAnotherModels: state => state.anotherModels.anotherModels,
            user: state => state.user.user.data
        }),
        status() {
            return {
                [STATUS_CREATED]: this.$tc('pages.user.setting.creator.verify_ondato.status_created'),
                [STATUS_PROCESSED]: this.$tc('pages.user.setting.creator.verify_ondato.status_processed'),
                [STATUS_RESOLVED]: this.$tc('pages.user.setting.creator.verify_ondato.status_resolved'),
                [STATUS_REJECTED]: this.$tc('pages.user.setting.creator.verify_ondato.status_rejected'),
            }
        },
        isShowVerificationButton() {
            if (!this.user.id) {
                return false;
            }
            const isVerified = !!this.user.verify_at
            if (isVerified) {
                return false
            }
            const {status} = this.verificationUser
            return !status || status === STATUS_REJECTED || status === STATUS_CREATED
        },
        showAnotherModels() {
            return !!this.user.verify_at
        },
        successMessage() {
            return this.$tc('pages.user.setting.creator.verify_ondato.model_has_verified', this.user.name, {name: this.user.name}) + " " + moment(this.user.verify_at).format('L')
        },
        hasUnverifiedModel() {
            if (!!this.anotherModels && this.anotherModels.length > 0) {
                return this.anotherModels.some(item => item.verify_at === null)
            }
            return false
        },
    },
    watch: {
        async user() {
            if (this.user.id === undefined) {
                return false
            }
            this.verificationUser = await this.getStatusVerificationUser(this.user.id)
        },
        async fetchedAnotherModels() {
            this.anotherModels = [...this.fetchedAnotherModels]
        },
    },
    methods: {
        ...mapActions([
            'getAnotherModels',
            'addAnotherModel',
            'getCurrentUserData',
        ]),
        async getStatusVerificationUser(id) {
            return await window.axios
                .get(`/api/ondato/status-verification-user/${id}`, { hideLoader: true })
                .then(({data, status}) => status === 200 ? data : null)
                .catch(() => null)
        },
        toVerificationUserLink() {
            this.loading.verificationUser = true
            return window.axios('api/ondato/verification-user-link')
                .then(({data}) => window.location.href = data.redirectUrl)
                .catch(e => {
                    console.log(e)
                })
                .finally(() => this.loading.verificationUser = false)
        },
        toVerificationAnotherModelLink({id}) {
            const index = this.anotherModels.findIndex(a => a.id === id)
            this.anotherModels[index].loading = true
            this.$set(this.anotherModels, index, this.anotherModels[index])
            return window.axios(`api/ondato/verification-another-model-link/${id}`)
                .then(({data}) => window.location.href = data.redirectUrl)
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    this.anotherModels[index].loading = false
                    this.$set(this.anotherModels, index, this.anotherModels[index])
                })
        },
        statusMessageAnotherModel({id, ondato}) {
            const message = this.$tc('pages.user.setting.creator.verify_ondato.model_not_verified', id, {name: id})
            if (!ondato) {
                return message
            }
            return `${message}. ${this.statusMessage(ondato)}`
        },
        statusMessage({status, date}) {
            if (!status || status === STATUS_CREATED) {
                return ''
            }
            return moment(date).format('L') + ' : ' + this.status[status]
        },
        isShowAnotherModelLink({ondato}) {
            return !ondato.status || ondato.status === STATUS_CREATED || ondato.status === STATUS_REJECTED
        },
        async handleAddAnotherModel() {
            this.addAnotherModel()
                .then(() => {
                    this.getAnotherModels()
                })
        },
    },
    created() {
        this.getAnotherModels()
        this.getCurrentUserData()
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/modules/_variables';

.success-message {
    & .v-icon {
        color: map-get($green, 'lighten-1');
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 40px;
        margin-right: 10px;
    }
}

.AnotherModels {
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 1em;
    }

    &-item {
        margin-bottom: 1em;
    }

    &-buttons {
        display: flex;
        justify-content: center;

        button {
            max-width: 320px;
            width: 100%;
        }
    }
}
</style>
